import React, { useState } from 'react';
import { Card, Col, Divider, Image, Modal, Row } from 'antd';
import EmptyContent from '../result/EmptyContent';
import SkeletonArtikel from '../skeleton/SkeletonArtikel';
import noImage from '../../../src/assets/images/no-image.png';
import editorJsHtml from 'editorjs-html';
import EditorJSViewer from '../editor/EditorJSViewer';
import EditorJSViewerSubstring from '../editor/EditorJSViewerSubstring';

const Artikel = (props) => {
    const edjsParser = editorJsHtml();

    const [modalContent, setModalContent] = useState({});
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = (item) => {
        setModalContent(item);
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onRenderModal = () => {
        return <Modal
            className='modal-artikel'
            width={1000}
            title={<h2 style={{ textAlign: 'center' }}>{modalContent.judul} </h2>}
            open={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel} footer={null}>
            <Image src={modalContent.file ? modalContent.main_path + modalContent.file : ''} alt={modalContent.judul} />
            <div className="modal-description">
                <EditorJSViewer data={modalContent.deskripsi}/>
            </div>
        </Modal>
    }


    if (props.isLoading) {
        return <SkeletonArtikel loading />
    }

    return <>
        <Row gutter={[16, 16]}>
            {props.items && props.items.length > 0 && props.items.map((item) => (
                <Col xs={24} sm={12} md={8} key={item.id}>
                    <Card
                        hoverable
                        cover={<img alt={item.judul} src={item.file ? item.main_path + item.file : noImage} />}
                        onClick={() => showModal(item)}
                    >
                        <Card.Meta title={item.judul} description={<EditorJSViewerSubstring data={item.deskripsi} limit={120}/>} />
                    </Card>
                </Col>
            )) || <EmptyContent onTryAgain={props.onRefetch} />}
        </Row>
        {onRenderModal()}
    </>
};
export default Artikel;