import React from 'react';
import { Row, Col } from 'antd';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

import './AplikasiSection.css'; // Pastikan untuk membuat file CSS ini

const AplikasiSection = () => {
  const { ref, inView } = useInView({
    triggerOnce: false, // Animasi hanya terjadi sekali
    threshold: 0.1, // 10% elemen terlihat
  });

  return (
    <section id="aplikasi" className="aplikasi-section">
      <div className="feature-header">
        <div className="divider-line"></div>
        <h2>Aplikasi Pendukung</h2>
      </div>
      <Row gutter={[16, 16]} justify="center">
        <Col xs={24} sm={6} md={6}>
          <a href="https://rdm.kemenag.go.id/" target="_blank" rel="noopener noreferrer">
            <motion.div className="feature-card"
              ref={ref}
              initial={{ opacity: 0, y: 100 }} // posisi awal (off-screen)
              animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }} // animasi masuk/keluar
              transition={{ duration: 0.5, ease: 'easeOut' }} // durasi dan easing animasi          
            >
              <img src="/images/logo-rapor-digital-madrasah.png" alt="Rapor Digital Madrasah" className="feature-image" />
              <h3>Rapor Digital Madrasah</h3>
              <p>Sistem penilaian online yang mencatat dan melaporkan prestasi siswa secara efisien dan transparan.</p>
            </motion.div>
          </a>
        </Col>
        <Col xs={24} sm={6} md={6}>
          <a href="https://ruangpintar.man1gresik.sch.id/" target="_blank" rel="noopener noreferrer">
            <motion.div className="feature-card"
              ref={ref}
              initial={{ opacity: 0, y: 100 }} // posisi awal (off-screen)
              animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }} // animasi masuk/keluar
              transition={{ delay: 0.1, duration: 0.5, ease: 'easeOut' }} // durasi dan easing animasi          
            >
              <img src="/images/logo-elearning-madrasah.png" alt="Rapor Digital Madrasah" className="feature-image" />
              <h3>E-Learning Madrasah</h3>
              <p>Platform pembelajaran online yang memfasilitasi akses materi, interaksi, dan penilaian bagi siswa.</p>
            </motion.div>
          </a>
        </Col>
        <Col xs={24} sm={6} md={6}>
          <a href="http://digilib.man1gresik.sch.id/" target="_blank" rel="noopener noreferrer">
            <motion.div className="feature-card"
              ref={ref}
              initial={{ opacity: 0, y: 100 }} // posisi awal (off-screen)
              animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }} // animasi masuk/keluar
              transition={{ delay: 0.2, duration: 0.5, ease: 'easeOut' }} // durasi dan easing animasi          
            >
              <img src="/images/logo-digilib.png" alt="Digilib" className="feature-image" />
              <h3>Digilib Madrasah</h3>
              <p>Koleksi buku dan informasi yang dapat diakses secara online untuk belajar siswa MAN 1 Gresik.</p>
            </motion.div>
          </a>
        </Col>
        <Col xs={24} sm={6} md={6}>
          <a href="https://man1gresik.sch.id/" target="_blank" rel="noopener noreferrer">
            <motion.div className="feature-card"
              ref={ref}
              initial={{ opacity: 0, y: 100 }} // posisi awal (off-screen)
              animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }} // animasi masuk/keluar
              transition={{ delay: 0.3, duration: 0.5, ease: 'easeOut' }} // durasi dan easing animasi          
            >
              <img src="/images/logo-man.png" alt="Goals" className="feature-image" />
              <h3>Web MAN 1 Gresik</h3>
              <p>Situs resmi untuk informasi mengenai kegiatan, akademik, dan layanan Madrasah Aliyah Negeri 1 Gresik.</p>
            </motion.div>
          </a>
        </Col>
      </Row>
    </section>
  );
};

export default AplikasiSection;
