import React from 'react';
import moment from 'moment';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

export const getDate = (value) => {
    let res = value
    if (value && value instanceof Date) {
        res = dayjs(moment(value).format("DD-MM-YYYY")).format("DD-MM-YYYY")
    }

    return res ? res : null
};
export const getYear = (value) => {
    let res = value
    if (value && value instanceof Date) {
        res = dayjs(moment(value).format("YYYY")).format("YYYY")
    }

    return res ? res : null
};