import React, { useEffect, useState } from "react";
import { useDebounce } from "use-debounce"; // Library khusus debounce
import { Select, Spin } from "antd";
import { useApi } from "../../hooks/api";

const { Option } = Select;

const SelectVillage = (props) => {
    const { data, loading, error, refetch } = useApi('/api/region/' + props.kecId);
    const [searchTerm, setSearchTerm] = useState("");
    const [kecId, setKecId] = useState(null);

    // Fetch data setiap kali debouncedSearchTerm berubah
    useEffect(() => {
        const fetchLocations = async () => {
            const payload = {
                "pagination": {
                    "current": 1, "pageSize": 100
                },
            }
            await refetch("POST", payload).then(() => {
                props.onSelect(null);
            });
        };

        fetchLocations();
    }, [refetch]);

    return (
        <Select
            value={props.kecId === kecId ? searchTerm : null}
            placeholder="Pilih Kelurahan"
            notFoundContent={loading ? <Spin style={{ margin: "0 auto" }} size="small" /> : null}
            onChange={(value) => {
                setKecId(props.kecId);
                setSearchTerm(value);
                props.onSelect(value);
            }}
            filterOption={false}
            style={{ width: "100%" }}
        >
            {data && data.data.map((location, index) => {
                return (
                    <Option key={index} value={location.id}>
                        {location.nama}
                    </Option>
                );
            })}
        </Select>
    );
};

export default SelectVillage;
