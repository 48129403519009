import React, { useState, useEffect } from "react";

const EditorJSViewerSubstring = ({data, limit}) => {
  const [viewData, setViewData] = useState(null);

  useEffect(() => {
    // Data JSON string

    try {
      // Parsing JSON
      const parsedData = JSON.parse(data || "{}");
      setViewData(parsedData);
    } catch (error) {
      console.error("Error parsing JSON:", error);
    }
  }, []);

  // Fungsi untuk membatasi karakter
  const truncateText = (text, limit) => {
    return text.length > limit ? text.substring(0, limit) + "..." : text;
  };

  return (
    <div>
      {viewData && viewData.blocks ? (
        viewData.blocks.map((block, index) => (
          <div key={index}>
            {block.type === "paragraph" && block.data.text && (
              <p>{truncateText(block.data.text, limit)}</p> // Tampilkan 100 karakter
            )}
          </div>
        ))
      ) : (
        <p>Loading data...</p>
      )}
    </div>
  );
};

export default EditorJSViewerSubstring;
