// src/App.js
import React from 'react';
import { Layout } from 'antd';
import AppHeader from './components/layouts/header/Header';
import AboutSection from './components/sections/hero/HeroSection';
import AppFooter from './components/layouts/footer/Footer';
import KegiatanSection from './components/sections/kegiatan/KegiatanSection';
import AplikasiSection from './components/sections/aplikasi/AplikasiSection';
import PrestasiSection from './components/sections/prestasi/PrestasiSection';
import ArtikelSection from './components/sections/artikel/ArtikelSection';
import GerbangSection from './components/sections/gerbang/GerbangSection';
import MenuBottomSection from './components/sections/menubottom/MenuBottomSection';

import './App.css';
const { Content } = Layout;

function App() {
  return (
    <Layout className="app-container">
      <AppHeader />
      <Content className="app-content">
        <AboutSection />
        <AplikasiSection/>
        <KegiatanSection />
        <PrestasiSection />
        <ArtikelSection />
        <GerbangSection />
        <MenuBottomSection />
      </Content>
      <AppFooter />
    </Layout>
  );
}

export default App;
