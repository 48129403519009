import React, { useEffect, useState } from "react";
import { useDebounce } from "use-debounce"; // Library khusus debounce
import { Select, Spin } from "antd";
import { useApi } from "../../hooks/api";

const { Option } = Select;

const SelectLocation = (props) => {
    const { data, loading, error, refetch } = useApi('/api/region');
    const [searchTerm, setSearchTerm] = useState("");
    const [debouncedSearchTerm] = useDebounce(searchTerm, 300); // 300ms debounce

    // Fetch data setiap kali debouncedSearchTerm berubah
    useEffect(() => {
        const fetchLocations = async () => {
            if (debouncedSearchTerm) {
                const payload = {
                    "pagination": {
                        "current": 1, "pageSize": 10
                    },
                    "search": debouncedSearchTerm
                }
                await refetch("POST", payload);
            }
        };

        fetchLocations();
    }, [debouncedSearchTerm, refetch]);

    const handleSearch = (value) => {
        setSearchTerm(value);
    };

    return (
        <Select
            allowClear
            showSearch
            value={searchTerm || null}
            placeholder="Pilih Provinsi, Kota & Kecamatan"
            notFoundContent={loading ? <Spin style={{ margin: "0 auto" }} size="small" /> : null}
            onSearch={handleSearch}
            onChange={(value) => {
                setSearchTerm(value);
                props.onSelect(value);
            }}
            filterOption={false}
            style={{ width: "100%" }}
        >
            {data && data.list.map((location, index) => {
                return (
                    <Option key={index} value={JSON.stringify(location)}>
                        {location.provinsi_nama}, {location.kabkot_nama}, {location.kecamatan_nama}
                    </Option>
                );
            })}
        </Select>
    );
};

export default SelectLocation;
