// src/components/Footer.js
import React from 'react';
import { Layout } from 'antd';
import './Footer.css'; // Buat file CSS khusus jika diperlukan

const { Footer } = Layout;

const AppFooter = () => {
  return (
    <Footer className="app-footer">
      &copy; Man 1 Gresik {new Date().getFullYear()} All Rights Reserved
    </Footer>
  );
};

export default AppFooter;
