import React from 'react';

import './GerbangSection.css';
const GerbangSection = () => {
  return (
    <section id="gerbang" className="gerbang-section">
      <div className="gerbang-section-box">
        <h2>Gerbang Utama untuk Siswa dan Alumni MAN 1 Gresik</h2>
      </div>
    </section>
  );
};

export default GerbangSection;
