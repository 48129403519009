import { useState, useCallback } from "react";
import axios from "axios";
import { message } from "antd";

const apiUrl = process.env.REACT_APP_API_URL;
const useApi = (url, initialOptions = {}) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [options, setOptions] = useState(initialOptions); // Store the options to be flexible

  // Fungsi untuk fetch data, bisa dipanggil ulang dengan metode dan payload yang berbeda
  const fetchApi = useCallback(async (method = "GET", payload = null) => {
    setLoading(true);
    try {
      const config = {
        method,
        url: `${apiUrl}${url}`,
        ...options,          // Merge dengan opsi yang ada (misalnya header)
        data: payload,       // Payload untuk POST, PUT, DELETE
      };

      const response = await axios(config);
      setData(response.data);
      setError(null);
    } catch (err) {
      if (err.response) {
        setData(null);
        setError(err.response.data);
        message.error(err.response.data.message);
      } else {
        setData(null);
        setError(err.message);
        message.error(err.message);
      }
    } finally {
      setLoading(false);
    }
  }, [url, options]);

  // // Fetch data pertama kali jika metode GET (atau bisa diatur sesuai kebutuhan)
  // useEffect(() => {
  //   if (options.method === 'GET' || !options.method) {
  //     fetchApi();
  //   }
  // }, [fetchApi]);

  // Mengembalikan data, loading, error, dan fungsi refetch dengan fleksibilitas untuk HTTP method
  return { data, loading, error, refetch: fetchApi, setOptions };
};

export default useApi;