import React, { useMemo } from 'react';
import { Button, Tabs } from 'antd';
import './TabsPrestasi.css';
const TabsPrestasi = (props) => {
  const slot = useMemo(() => {
    return ['left'].reduce(
      (acc, direction) => ({
        ...acc,
        [direction]: (
          props.title && (
              <h2 className='title-tab-prestasi'>{props.title}</h2>
          )
        ),
      }),
      {},
    );
  }, []);

  return <Tabs tabBarExtraContent={slot} defaultActiveKey="1" items={props.items} />
};
export default TabsPrestasi;