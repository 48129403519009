import React from 'react';
import { Row, Col, Card, Skeleton } from 'antd';

const SkeletonArtikel = ({ loading }) => {
  const articles = [1, 2, 3]; // Placeholder jumlah artikel

  return (
    <Row gutter={[16, 16]}>
      {articles.map((_, index) => (
        <Col xs={24} sm={12} md={8} lg={8} key={index}>
          <Card bordered={false}>
            <Skeleton loading={loading} active>
              <Card.Meta
                title="Article Title"
                description="This is a description placeholder for an article."
              />
            </Skeleton>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default SkeletonArtikel;
