import React from 'react';
import { Typography, Button, Modal, Drawer } from 'antd';
import { BellOutlined } from '@ant-design/icons';
import { AlumniRegistrationForm } from '../../form';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import { isBrowser, isMobile } from 'react-device-detect';
import GuestBookRegistrationForm from '../../form/FormGuestBook';

import './HeroSection.css'; // Buat file CSS khusus jika diperlukan

const ppdbWebLink = process.env.REACT_APP_PPDB_WEB_URL;
const { Title, Text } = Typography;

const AboutSection = () => {
  const [visibleFormAlumni, setVisibleFormAlumni] = React.useState(false);
  const [visibleFormGuestBook, setVisibleFormGuestBook] = React.useState(false);

  const { ref, inView } = useInView({
    triggerOnce: false, // Animasi loop
    threshold: 0.1, // 10% elemen terlihat
  });

  return (
    <div id="home" className="hero-section">
      <div className="info-section">
        <motion.div
          ref={ref}
          initial={{ opacity: 0, x: -50 }} // posisi awal (off-screen)
          animate={inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -50 }} // animasi masuk/keluar
          transition={{ duration: 0.5, ease: 'easeOut' }} // durasi dan easing animasi          
        >
          <Title level={5} className="hero-subtitle">🚀 Lebih canggih, dan lebih handal</Title>
        </motion.div>
        <motion.div
          ref={ref}
          initial={{ opacity: 0, x: -50 }} // posisi awal (off-screen)
          animate={inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -50 }} // animasi masuk/keluar
          transition={{ delay: 0.2, duration: 0.5, ease: 'easeOut' }} // durasi dan easing animasi          
        >
          <Title level={1} className="hero-title">Menuju MAN 4.0 Berbasis Teknologi Digital</Title>
        </motion.div>
        <motion.div
          ref={ref}
          initial={{ opacity: 0, x: -50 }} // posisi awal (off-screen)
          animate={inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -50 }} // animasi masuk/keluar
          transition={{ delay: 0.4, duration: 0.5, ease: 'easeOut' }} // durasi dan easing animasi          
        >
          <Text className="hero-description">
            Sistem informasi MAN Digital adalah sebuah sistem aplikasi berbasis web yang digunakan untuk membantu
            mengelola pelayanan pengelolaan data Madrasah dalam peningkatan pelayanan anggota Madrasah secara lebih
            cepat dan efisien, selain itu sistem ini akan membantu dan memudahkan dalam penambahan data pada aplikasi
            pendukung pendidikan yang resmi.
          </Text>
        </motion.div>

        <motion.div
          ref={ref}
          initial={{ opacity: 0, x: -50 }} // posisi awal (off-screen)
          animate={inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -50 }} // animasi masuk/keluar
          transition={{ delay: 0.6, duration: 0.5, ease: 'easeOut' }} // durasi dan easing animasi          
        >
          {/* Bagian Pengumuman */}
          <div className="announcement-box">
            <div className="announcement-content">
              <div className="announcement-text">
                <div className='announcement-content'>
                  <BellOutlined className="announcement-icon" />
                  <div style={{ marginTop: '12px' }}>
                    <h4>Pengumuman Penting</h4>
                    <p>-</p>
                  </div>
                </div>
                <div className="buttons">
                  <a href={ppdbWebLink} target='_blank'><Button type="primary" className="custom-button">PPDB 2024</Button></a>
                  <Button className="custom-button" onClick={() => setVisibleFormAlumni(!visibleFormAlumni)}>Pendaftaran Alumni</Button>
                  <Button className="custom-button" onClick={() => setVisibleFormGuestBook(!visibleFormGuestBook)}>Buku Tamu</Button>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </div>


      {/* Modal for Desktop */}
      {isBrowser && (
        <>
          <Modal
            maskClosable={false}
            keyboard={false}
            title="Form Registrasi Alumni"
            open={visibleFormAlumni}
            onCancel={() => setVisibleFormAlumni(false)}
            footer={null}
          >
            <AlumniRegistrationForm />
          </Modal>
          <Modal
            maskClosable={false}
            keyboard={false}
            title="Form Buku Tamu"
            open={visibleFormGuestBook}
            onCancel={() => setVisibleFormGuestBook(false)}
            footer={null}
          >
            <GuestBookRegistrationForm cancelled={visibleFormGuestBook} />
          </Modal>
        </>
      )}


      {/* Drawer for Mobile */}
      {isMobile && (
        <>
          <Drawer
            placement="right"
            onClose={() => setVisibleFormAlumni(false)}
            open={visibleFormAlumni}>
            <AlumniRegistrationForm />
          </Drawer>
          <Drawer
            placement="right"
            onClose={() => setVisibleFormGuestBook(false)}
            open={visibleFormGuestBook}>
            <GuestBookRegistrationForm/>
          </Drawer>
        </>
      )}
    </div>
  );
};

export default AboutSection;
