import React from 'react';
import { Button } from 'antd';

const ButtonLoadmore = ({isShow, payload, refetch}) => {
    if (!isShow) {
        return null;
    }

    return (
        <div style={{ textAlign: 'center', marginTop: '16px' }}>
            <Button className="loadmore-button" onClick={() => refetch("POST", {
                ...payload,
                pagination: {
                    ...payload.pagination,
                    current: payload.pagination.current + 1
                }
            })}>Load More</Button>
        </div>
    );
}
export default ButtonLoadmore;