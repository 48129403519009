// KegiatanSection.js
import React, { useEffect } from 'react';
import TabsPrestasi from '../../tabs/TabsPrestasi';
import Artikel from '../../artikel/Artikel';
import { useApi } from '../../../hooks/api';

import './PrestasiSection.css';
import ButtonLoadmore from '../../button/ButtonLoadmore';

const KegiatanSection = () => {
  const titlePrestasi = "🏆 Prestasi Yang Diraih"
  const urlApiKeegiatan = "/api/prestasi-sekolah/status";
  const payload = {
    "pagination": {
      "current": 1, "pageSize": 6
    }, "filtering": {
      "kategori": "Siswa"
    }
  }
  const payloadSiswa = {
    ...payload,
    filtering: {
      kategori: "Siswa"
    }
  }
  const payloadGuru = {
    ...payload,
    filtering: {
      kategori: "Guru"
    }
  }
  const payloadMadrasah = {
    ...payload,
    filtering: {
      kategori: "Madrasah"
    }
  }
  const { data: dataSiswa, loading: loadingSiswa, refetch: refetchSiswa } = useApi(urlApiKeegiatan);
  const { data: dataGuru, loading: loadingGuru, refetch: refetchGuru } = useApi(urlApiKeegiatan);
  const { data: dataMadrasah, loading: loadingMadrasah, refetch: refetchMadrasah } = useApi(urlApiKeegiatan);

  const itemsTabs = [
    {
      key: 'siswa',
      label: 'Siswa',
      children: <div>
        <Artikel isLoading={loadingSiswa} items={dataSiswa && dataSiswa.list} onRefetch={() => {
          refetchSiswa("POST", payloadSiswa);
        }} />
        <ButtonLoadmore payload={payloadSiswa} isShow={dataSiswa && dataSiswa.list.length > 0 ? true : false} refetch={() => refetchSiswa("POST", payloadSiswa)} />
      </div>,
    },
    {
      key: 'guru',
      label: 'Guru',
      children: <div>
        <Artikel isLoading={loadingGuru} items={dataGuru && dataGuru.list} onRefetch={() => {
          refetchGuru("POST", payloadGuru);
        }} />
        <ButtonLoadmore payload={payloadGuru} isShow={dataGuru && dataGuru.list.length > 0 ? true : false} refetch={() => refetchGuru("POST", payloadGuru)} />
      </div>,
    },
    {
      key: 'madrasah',
      label: 'Madrasah',
      children: <div>
        <Artikel isLoading={loadingMadrasah} items={dataMadrasah && dataMadrasah.list} onRefetch={() => {
          refetchMadrasah("POST", payloadMadrasah);
        }} />
        <ButtonLoadmore payload={payloadMadrasah} isShow={dataMadrasah && dataMadrasah.list.length > 0 ? true : false} refetch={() => refetchMadrasah("POST", payloadMadrasah)} />
      </div>,
    },
  ];

  useEffect(() => {
    refetchSiswa("POST", payloadSiswa);
    refetchGuru("POST", payloadGuru);
    refetchMadrasah("POST", payloadMadrasah);
  }, []);

  return (
    <section id="prestasi" className="prestasi-section">
      <div className="feature-header title-prestasi">
        <div className="divider-line"></div>
        <h2>{titlePrestasi}</h2>
      </div>
      <TabsPrestasi title={titlePrestasi} items={itemsTabs} />
    </section>
  );
};

export default KegiatanSection;
