import React from 'react';
import { Result } from 'antd';
const SuccessContent = ({title, subTitle, extra}) => (
  <Result
    status="success"
    title={title}
    subTitle={subTitle}
    extra={extra}
  />
);
export default SuccessContent;