import React, { useEffect } from 'react';
import { useApi } from '../../../hooks/api';
import Artikel from '../../artikel/Artikel';
import ButtonLoadmore from '../../button/ButtonLoadmore';

import './ArtikelSection.css';
const ArtikelSection = () => {
  const { data, loading, refetch } = useApi('/api/kegiatan-sekolah/status');
  const payload = {
    "pagination": {
      "current": 1, "pageSize": 3
    },
    "filtering": {
      "kategori": "Artikel"
    }
  }

  useEffect(() => {
    refetch("POST", payload);
  }, []);

  return (
    <section id="artikel" className="artikel-section">
      <div className="feature-header">
        <div className="divider-line"></div>
        <h2>Artikel</h2>
      </div>
      <Artikel isLoading={loading} items={data && data.list} onRefetch={() => {
        refetch("POST", payload);
      }} />
        <ButtonLoadmore payload={payload} isShow={data && data.list.length > 0 ? true : false} refetch={() => refetch("POST", payload)} />
    </section>
  );
};

export default ArtikelSection;
