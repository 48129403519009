import React, { useState } from 'react';
import { Form, Input, Button, Upload, DatePicker, Select, Row, Col, Collapse, message, Image, Divider } from 'antd';
import { CloseCircleOutlined, InboxOutlined, UploadOutlined } from '@ant-design/icons';
import Dragger from 'antd/es/upload/Dragger';
import { useApi } from '../../hooks/api';
import SelectLocation from '../select/SelectLocation';
import SelectVillage from '../select/SelectVillage';
import { getBase64 } from '../../utils/Upload';
import { getDate, getYear } from '../../utils/Date';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import "./style.css";
import SuccessContent from '../result/SuccessContent';

dayjs.extend(customParseFormat);

const { Option } = Select;
const onChange = (key) => {
    console.log(key);
};

const AlumniRegistrationForm = () => {
    const [form] = Form.useForm();
    const { data, loading, error, refetch } = useApi('/api/alumni/data-diri');

    const [preview, setPreview] = useState(null);
    const [fileList, setFileList] = useState([]);
    const [locationList, setLocationList] = useState([]);
    const [fileProfile, setFileProfile] = useState(null);

    const onFinish = async (values) => {

        if (!fileProfile?.fileList) {
            return message.warning('Harap upload foto profil terlebih dahulu!')
        }

        refetch("POST", {
            foto: fileProfile?.fileList ? await getBase64(fileProfile.fileList) : null,
            nik: values.nik,
            nama: values.name,
            jenis_kelamin: values.gender,
            tempat_lahir: values.birthPlace,
            tgl_lahir: getDate(values.birthDate),
            no_telp: values.phone,
            email: values.email,
            provinsi_id: values?.location?.provinsi_id,
            kabkot_id: values?.location?.kabkot_id,
            kecamatan_id: values?.location?.kecamatan_id,
            kelurahan_id: values?.village,
            kode_pos: values?.postalCode,
            alamat: values?.address,
            tahun_masuk: new Date(values?.entryYear).getFullYear(),
            tahun_lulus: new Date(values?.graduationYear).getFullYear(),
            file_ijazah: fileList ? await getBase64(fileList) : null,
        }).then(() => {
            if (data && data.status === true) {
                message.success(data.status);
                form.resetFields();
            }
        });
        // Reset form
        // Close modal
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
        // Munculkan message warning jika ada error
        message.warning('Harap isi semua kolom yang diperlukan dengan benar sebelum submit!');
    };


    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    const handleBeforeUpload = (file) => {
        const allowedTypes = [
            'image/jpeg',
            'image/png',
            'application/pdf',
            'application/vnd.ms-excel',  // Excel (xls)
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',  // Excel (xlsx)
            'application/msword',  // Word (doc)
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',  // Word (docx)
            'text/csv',  // CSV
        ];

        if (!allowedTypes.includes(file.type)) {
            message.error(`${file.name} tidak didukung. Harap unggah file dengan format yang diizinkan.`);
            return Upload.LIST_IGNORE;
        }

        const isLt10M = file.size / 1024 / 1024 < 10;
        if (!isLt10M) {
            message.error('File harus lebih kecil dari 10MB!');
            return Upload.LIST_IGNORE;
        }

        setFileList((prevList) => [
            ...prevList,
            { ...file, status: 'done' },
        ]);

        return false;  // Mengembalikan false untuk mencegah unggah otomatis
    };

    const handleChangeUpload = ({ fileList }) => {
        setFileList(fileList);
    };

    const handleLocationSelect = (location) => {
        const loc = location && JSON.parse(location);
        setLocationList(loc);
        form.setFieldsValue({ location: loc }); // Set value form dengan objek lokasi
    };

    const handleVillageSelect = (kelurahan_id) => {
        form.setFieldsValue({ village: kelurahan_id });
    };

    const items = [
        {
            key: '1',
            label: 'Pendaftaran Alumni - Data Diri',
            children: <>
                {/* NIK */}
                <Form.Item
                    name="nik"
                    label="NIK"
                    validateTrigger="onBlur"
                    rules={[{
                        required: true,
                        message: 'Harap masukkan NIK!'
                    }, {
                        pattern: /^[0-9]*$/,
                        message: 'NIK harus berupa angka saja!',
                    }, {
                        len: 16,
                        message: 'NIK harus 16 digit!'
                    }]}
                >
                    <Input maxLength={16} placeholder="Masukkan NIK" type="text" />
                </Form.Item>

                {/* Nama Alumni */}
                <Form.Item
                    name="name"
                    label="Nama Alumni"
                    validateTrigger="onBlur"
                    rules={[{ required: true, message: 'Harap masukkan nama!' }]}
                >
                    <Input placeholder="Masukkan nama lengkap" />
                </Form.Item>

                {/* Jenis Kelamin */}
                <Form.Item
                    name="gender"
                    label="Jenis Kelamin"
                    validateTrigger="onBlur"
                    rules={[{ required: true, message: 'Harap pilih jenis kelamin!' }]}
                >
                    <Select placeholder="Pilih jenis kelamin">
                        <Option value="Laki-laki">Laki-laki</Option>
                        <Option value="Perempuan">Perempuan</Option>
                    </Select>
                </Form.Item>

                {/* Tempat Lahir */}
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="birthPlace"
                            label="Tempat Lahir"
                            validateTrigger="onBlur"
                            rules={[{ required: true, message: 'Harap masukkan tempat lahir!' }]}
                        >
                            <Input placeholder="Masukkan tempat lahir" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="birthDate"
                            label="Tanggal Lahir"
                            validateTrigger="onBlur"
                            rules={[{ required: true, message: 'Harap masukkan tanggal lahir!' }]}
                        >
                            <DatePicker style={{ width: '100%' }} placeholder="Pilih tanggal lahir" />
                        </Form.Item>
                    </Col>
                </Row>

                {/* No Telp */}
                <Form.Item
                    name="phone"
                    label="No Telp"
                    validateTrigger="onBlur"
                    extra={<span style={{ fontStyle: 'italic' }}>Pastikan nomor telepon dan WhatsApp Anda aktif, karena verikasi akan dilakukan menggunakan nomor ini.</span>}
                    rules={[{
                        required: true,
                        message: 'Harap masukkan nomor telepon!'
                    }, {
                        pattern: /^\+?[0-9]*$/,
                        message: 'Nomor telepon tidak valid!',
                    }]}

                >
                    <Input placeholder="Masukkan nomor telepon" type="tel" />
                </Form.Item>

                {/* E-Mail */}
                <Form.Item
                    name="email"
                    label="E-Mail"
                    validateTrigger="onBlur"
                    rules={[
                        { required: true, message: 'Harap masukkan email!' },
                        { type: 'email', message: 'Email tidak valid!' },
                    ]}
                >
                    <Input placeholder="Masukkan email" />
                </Form.Item>
            </>,
        },
        {
            key: '2',
            label: 'Pendaftaran Alumni - Alamat',
            children: <>
                {/* Kota & Kecamatan */}
                <Form.Item
                    name="location"
                    label="Province, Kota & Kecamatan"
                    validateTrigger="onBlur"
                    rules={[{ required: true, message: 'Harap masukkan kota dan kecamatan!' }]}
                >
                    <SelectLocation onSelect={handleLocationSelect} />
                </Form.Item>

                {/* Kelurahan & Kode pos*/}
                <Row gutter={16}>
                    <Col span={16}>
                        <Form.Item
                            name="village"
                            label="Kelurahan"
                            validateTrigger="onBlur"
                            rules={[{ required: true, message: 'Harap pilih kelurahan!' }]}
                        >
                            <SelectVillage kecId={locationList && locationList.kecamatan_id} onSelect={handleVillageSelect} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="postalCode"
                            label="Kode Pos"
                            validateTrigger="onBlur"
                            rules={[{
                                required: true,
                                message: 'Harap masukkan kode pos!',
                            }, {
                                pattern: /^[0-9]*$/,
                                message: 'Kode pos harus berupa angka saja!',
                            }, {
                                len: 5,
                                message: 'Kode pos harus 5 digit!'
                            }]}>
                            <Input maxLength={5} placeholder="Kode pos" />
                        </Form.Item>
                    </Col>
                </Row>

                {/* Alamat */}
                <Form.Item name="address" label="Alamat"
                    rules={[{ required: true, message: 'Harap masukkan alamat!' }]}>
                    <Input.TextArea rows={4} placeholder="Masukkan alamat lengkap" />
                </Form.Item>
            </>,
        },
        {
            key: '3',
            label: 'Pendaftaran Alumni - Pendidikan',
            children: <>
                {/* Tahun Masuk dan lulus */}
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="entryYear"
                            label="Tahun Masuk"
                            rules={[{ required: true, message: 'Harap pilih tahun masuk!' }]}
                        >
                            <DatePicker picker="year" style={{ width: '100%' }} placeholder="Pilih tahun masuk" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="graduationYear"
                            label="Tahun Lulus"
                            rules={[{ required: true, message: 'Harap pilih tahun lulus!' }]}
                        >
                            <DatePicker picker="year" style={{ width: '100%' }} placeholder="Pilih tahun lulus" />
                        </Form.Item>
                    </Col>
                </Row>

                {/* Upload Ijazah */}
                <Form.Item
                    name="fileList"
                    label="Upload File Ijazah"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    rules={[{ required: true, message: 'Harap upload file ijazah!' }]}
                >
                    <Dragger
                        name="file_ijazah"
                        beforeUpload={handleBeforeUpload}
                        onChange={handleChangeUpload}
                        fileList={fileList}
                        maxCount={1}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click atau drag file ke area ini untuk mengunggah</p>
                        <p className="ant-upload-hint">File yang diizinkan: JPG, PNG, PDF, CSV, Excel, Word (Maksimal 10MB)</p>
                    </Dragger>
                </Form.Item>
            </>
        },
    ];

    if (data && data.data) {
        return <SuccessContent
            title="Pendaftaran Alumni Berhasil!"
            subTitle="Akun berhasil didaftarkan. Verifikasi akun Anda sedang dilakukan. Kami akan menginformasikan status verikasi Anda melalui nomor WhatsApp yang telah terdaftar!" />
    }

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={{ gender: 'Laki-laki' }}
        >
            {/* Upload Profile */}
            <Form.Item
                name="profile"
                label=""
                valuePropName="fileList"
                getValueFromEvent={normFile}
            >
                <div style={{ textAlign: 'center' }}>
                    {preview && (
                        <>
                            <div className='close-preview'><CloseCircleOutlined style={{ cursor: 'pointer' }} onClick={() => {
                                setPreview(null);
                                setFileProfile(null);
                            }} /></div>
                            <Image
                                src={preview}
                                alt="Preview"
                                style={{ width: '100%', marginTop: '10px' }}
                            /></>
                    )}

                    {!preview && <Upload
                        accept="image/*"
                        name="profile"
                        listType="picture-circle"
                        beforeUpload={() => false}
                        maxCount={1}
                        showUploadList={false}
                        onChange={(fileObj) => {
                            if (fileObj && fileObj.file) {
                                setFileProfile(fileObj);
                                setPreview(URL.createObjectURL(fileObj.file));
                            } else {
                                setFileProfile(null);
                                setPreview(null);
                            }
                        }}
                    >
                        <UploadOutlined />
                    </Upload>}
                </div>
            </Form.Item>

            <Collapse accordion items={items} defaultActiveKey={['1']} onChange={onChange} />

            <Divider />
            {/* Submit Button */}
            <Form.Item>
                <Button type="primary" htmlType="submit" block loading={loading}>
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
};

export default AlumniRegistrationForm;