import React, { useState, useEffect } from "react";
import editorJsHtml from 'editorjs-html';

const EditorJSViewer = ({ data }) => {
  const [viewData, setViewData] = useState(null);
  const edjsParser = editorJsHtml();

  useEffect(() => {
    // Data dalam bentuk JSON string (dari server atau input)

    try {
      // Parsing JSON string menjadi objek JavaScript
      const parsedData = JSON.parse(data || "{}");

      // Periksa apakah parsedData memiliki blocks dan apakah itu array
      if (parsedData.blocks && Array.isArray(parsedData.blocks)) {
        setViewData(parsedData);
      } else {
        console.error("Invalid format: blocks is missing or not an array");
      }
    } catch (error) {
      console.error("Error parsing JSON:", error);
    }
  }, []);

  return (
    <div>
      {viewData && viewData.blocks ? (
        edjsParser.parse(viewData).map((block, index) => (
          <div key={index} dangerouslySetInnerHTML={{ __html: block }} />
        ))
      ) : (
        <p>Loading data or invalid format...</p>
      )}
    </div>
  );
};

export default EditorJSViewer;
