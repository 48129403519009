// src/components/Header.js
import React, { useState } from 'react';
import { Layout, Menu, Typography, Drawer, Button, Grid } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { Link, animateScroll as scroll } from 'react-scroll';

import './Header.css';

const { Header } = Layout;
const { Title, Text } = Typography;
const { useBreakpoint } = Grid;
const webExternalLink = process.env.REACT_APP_WEB_EXTERNAL_URL;

const AppHeader = () => {
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState('0');
  const screens = useBreakpoint();

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <Header className="header">
      {/* Bagian Kiri: Logo, Title, dan Deskripsi */}
      <div className="header-left">
        <div className="logo">
          <img src="images/logo-man.png" alt="Logo" />
        </div>
        <div className="header-text">
          <Title level={5} className="header-title">MAN 1 GRESIK</Title>
          <Text className="header-description">Islami, Cerdas, Unggul, Kompetitif & Peduli Lingkungan</Text>
        </div>
      </div>

      {/* Bagian Kanan: Menu Navigasi (Desktop) */}
      {screens.lg ? (
        <Menu theme="light" mode="horizontal" defaultSelectedKeys={["0"]} selectedKeys={[selected]}>
          <Menu.Item key="0"><Link to="home" smooth={true} duration={500} onClick={() => setSelected('0')}>Pengumuman</Link></Menu.Item>
          <Menu.Item key="1"><Link to="aplikasi" smooth={true} duration={500} onClick={() => setSelected('1')}>Aplikasi</Link></Menu.Item>
          <Menu.Item key="2"><Link to="kegiatan" smooth={true} duration={500} onClick={() => setSelected('2')}>Kegiatan</Link></Menu.Item>
          <Menu.Item key="3"><Link to="prestasi" smooth={true} duration={500} onClick={() => setSelected('3')}>Prestasi</Link></Menu.Item>
          <Menu.Item key="4"><Link to="artikel" smooth={true} duration={500} onClick={() => setSelected('4')}>Artikel</Link></Menu.Item>
          <Menu.Item key="5"><a href={webExternalLink} target='_blank'><Button type="primary">Login</Button></a></Menu.Item>
        </Menu>
      ) : (
        <>
          {/* Tombol Hamburger (Mobile) */}
          <Button className="header-menu-mobile" type="secondary" icon={<MenuOutlined />} onClick={showDrawer} />

          {/* Drawer untuk Menu Mobile */}
          <Drawer
            title="Menu"
            placement="right"
            onClose={onClose}
            open={visible}
            className="header-drawer"
            width="100vw"
          >
            <Menu theme="light" mode="vertical" defaultSelectedKeys={['1']}>
              <Menu.Item key="0"><Link to="home" smooth={true} duration={500} onClick={() => setSelected('0')}>Pengumuman</Link></Menu.Item>
              <Menu.Item key="1"><Link to="aplikasi" smooth={true} duration={500} onClick={() => setSelected('1')}>Aplikasi</Link></Menu.Item>
              <Menu.Item key="2"><Link to="kegiatan" smooth={true} duration={500} onClick={() => setSelected('2')}>Kegiatan</Link></Menu.Item>
              <Menu.Item key="3"><Link to="prestasi" smooth={true} duration={500} onClick={() => setSelected('3')}>Prestasi</Link></Menu.Item>
              <Menu.Item key="4"><Link to="artikel" smooth={true} duration={500} onClick={() => setSelected('4')}>Artikel</Link></Menu.Item>
              <Menu.Item key="5"><a href={webExternalLink} target='_blank'><Button type="primary">Login</Button></a></Menu.Item>
            </Menu>
          </Drawer>
        </>
      )}
    </Header>
  );
};

export default AppHeader;
