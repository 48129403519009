import React from 'react';
import { Button, Empty, Typography } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';

const EmptyContent = (props) => (
  <Empty
    style={{
      margin: '0 auto',
    }}
    image="/images/empty.svg"
    imageStyle={{
      height: 60,
    }}
    description={
      <Typography.Text>
       Tidak ada data untuk ditampilkan
      </Typography.Text>
    }
  >
    <Button icon={<ReloadOutlined />} loading={props.loading} onClick={props.onTryAgain}>Try Again</Button>
  </Empty>
);
export default EmptyContent;