import React from 'react';

import './MenuBottomSection.css';
const MenuBottomSection = () => {
  return (
    <section id="menubottom" className="menubottom-section">
      <div className="logo-bottom"></div>
      <h2>MAN 1 GRESIK</h2>
      <p>Islami, Cerdas, Unggul, Kompetitif, & Peduli Lingkungan</p>
    </section>
  );
};

export default MenuBottomSection;
